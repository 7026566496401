import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import {
  Jumbo,
  Welcome,
  Equipment,
  Kit,
  Location,
  Social,
  Questions
} from "../components/sections"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Jumbo/>
    <Welcome/>
    <div id="radiant"/>
    <Equipment/>
    <div id="kit"/>
    <Kit/>
    <div id="location"/>
    <Location/>
    <div id="social"/>
    <Social/>
    <div id="FAQ"/>
    <Questions/>
  </Layout>
)

export default IndexPage
